.landing-container {
  display: flex;
  margin-top: 15vh;
  margin-bottom: 20vh;
}

.lottie {
  width: 45vw;
  z-index: -1;
  align-self: center;
  justify-self: center;
  margin-top: -320px;
}

.i-am-zack {
  animation: fadeIn 0.8s ease-in-out;
  display: flex;
  font-family: "Unbounded", sans-serif;
  color: black;
  font-size: 40px;
  margin-left: 15vw;
}

.sliding-text {
  font-family: "Unbounded", sans-serif;
  display: flex;
  flex-direction: row;
  width: 40vw;
  justify-content: flex-start;
  margin-left: 15vw;
  color: black;
  gap: 1vw;
  animation: fadeIn 0.8s ease-in-out;
}

.Logo{
  width: 10vw;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

h2::after {
  content: "";
  display: inline-block;
  width: 2px;
  height: 23px;
  margin-left: 5px;
  margin-bottom: -4px;
  background-color: black;
  animation: blinking-cursor 1s infinite;
}

@keyframes blinking-cursor {
  from,
  to {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

.hello {
  display: flex;
  flex-direction: column;
  width: auto;
  justify-content: center;
}

.my-image-container {
  width: 50vw;
  display: flex;
  justify-content: center;
  border-left: 1px solid #d6d6da;
}

.my-image {
  width: 20vw;
  border-radius: 50%;
}

@media (max-width: 430px) {
  .i-am-zack {
    font-size: 18px;
    width: auto;
    margin-top: 0;
  }

  .switch {
    margin-top: -6vh;
    margin-left: 1vw;
  }

  .landing-container {
    flex-direction: column;
    margin-top: 2vh;
    margin-bottom: 3vh;
  }

  .sliding-text {
    flex-direction: column;
    width: 85vw;
    margin-bottom: 5vh;
  }

  .my-image {
    width: 60vw;
    margin-left: 50vw;
  }

  .sliding-text::after{
    content: none;
  }

  .my-image-container{
    border-left: none;
  }

  .Logo{
    width: 30vw;
  }
}
