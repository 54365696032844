/* Fonts */
/* font-family: "Libre Caslon Text", serif; */
/* font-family: "Spectral", serif; */
/* font-family: "Unbounded", sans-serif; */
/* font-family: "Climate Crisis", sans-serif; */

::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

body {
  background-size: 100%;
  background-repeat: no-repeat;
  font-family: "Unbounded", sans-serif;
  background-color: white;
}

.app-container{
  margin-bottom: 5vh;
}

.switch {
  display: flex;
  font-family: "Unbounded", sans-serif;
  align-items: center;
  margin-top: -7vh;
  margin-left: 13vw;
}

#light .switch label {
  color: black;
}

#dark .switch label {
  color: white;
}

#dark {
  background-color: #0C1618;
  transition: 1s ease-in;
}

#dark nav .links a.nav-a{
  color: white;
}

#dark .i-am-zack,
.se {
  z-index: 2;
  color: white;
}

#dark .sliding-text{
  color: white;
}

#dark .logo {
  background-color: #ffffff7d;
  border-radius: 50%;
  margin-bottom: 1vh;
}

.react-switch-bg {
  height: 35px;
  width: 3.5vw;
  margin: 0px;
  position: relative;
  background: rgb(242, 237, 237);
  border-radius: 14px;
  cursor: pointer;
  transition: background 0.25s ease 0s;
  padding-left: 5px;
}

@media (max-width: 430px) {
  body {
    background-image: none;
  }

  #dark .logo{
    width: 30px;
  }
}
