.personal-container {
  margin-top: 50px;
}

.whoami {
  display: flex;
  width: 95vw;
  padding-top: 5px;
  padding-left: 50px;
  perspective: 100px;
}

.whoami-2 {
  width: 50vw;
  display: flex;
  flex-direction: column;
}

#dark {
  color: white;
}

#dark .lottieInsta {
  z-index: 1;
}

#dark .see-more-insta {
  position: inherit;
  z-index: 2;
}

@keyframes reverse-scroll {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(20%);
  }
}

.img-me {
  width: 45vw;
  overflow: hidden;
  transform: translateY(0);
}

.img-william {
  width: 45vw;
  padding-left: 50px;
  margin-top: -200px;
}

.lottieInsta {
  width: 250px;
  position: relative;
  z-index: -1;
}

p.see-more-insta {
  margin-left: 50px;
  color: white;
  width: 150px;
  margin-top: -150px;
}

.instagram-linking {
  text-decoration: none;
  font-family: "Unbounded", sans-serif;
}

.whoami p {
  font-size: larger;
}

.intro {
  width: 85%;
  padding-left: 0;
  font-family: "Unbounded", sans-serif;
  font-size: x-large;
}

.photo {
  padding-left: 40px;
  display: flex;
  gap: 20px;
}

.instagram-link {
  padding: 0 0 0 200px;
  align-self: center;
  flex-wrap: wrap;
  width: 100px;
  text-align: center;
  margin-top: -50px;
}

.photo a:hover {
  color: rgb(175, 0, 99);
  font-size: 16px;
}

.photos-container {
  display: flex;
  gap: 60px;
  font-family: "Spectral", serif;
}

.photos-container h3 {
  text-decoration: none;
  text-align: start;
  font-family: "Unbounded", sans-serif;
  font-weight: 400;
}

.about-photography {
  margin-right: 20px;
  font-family: "Spectral", serif;
}

p.about-photography {
  width: 90%;
}

.photography-text h4 {
  margin-top: 30px;
  font-family: "Unbounded", sans-serif;
  font-weight: 300;
}

.photography-text {
  margin-left: 10px;
}

.about-me {
  font-family: "Spectral", serif;
  width: 85%;
}

.travel-image {
  width: 45vw;
  margin-top: 600px;
}

.traveling-container {
  display: flex;
  position: relative;
}

.travel-info {
  padding-left: 50px;
  width: 45vw;
}

.instagram-linking {
  position: relative;
}

h4.travel-info {
  font-family: "Unbounded", sans-serif;
  font-weight: 300;
  padding-top: 50px;
  margin-top: 50%;
}

@media (max-width: 842px) {
  h1.intro {
    width: 800px;
  }

  p.about-me {
    width: 800px;
  }
}

@media (max-width: 430px) {
  h1.intro {
    display: flex;
    width: 350px;
    font-size: medium;
  }

  p.about-me {
    width: 40vw;
    font-size: medium;
  }

  div.photo {
    padding-left: 10px;
    overflow: auto;
  }

  .instagram-link {
    padding: 0;
  }

  .whoami {
    padding-right: 0;
    margin-left: -40px;
  }

  .photos-container {
    gap: 0;
    margin-left: -40px;
  }

  .travel {
    padding-top: 0;
  }

  .img-william {
    width: 50vw;
    margin-top: 50vh;
  }

  .img-me {
    width: 45vw;
    overflow: hidden;
    transform: translateY(0);
    height: 100%;
    margin-top: 20vh;
  }

  h4.travel-info {
    margin-top: 20vh;
  }

  .text-content h3 {
    width: 100vw;
    margin-left: -250px;
  }

  .about-photography {
    display: flex;
    flex-direction: column;
  }

  .instagram-link {
    margin-left: -100px;
  }

  div.photos-container h3 {
    padding-left: 40px;
  }

  .travel-image {
    width: 45vw;
    margin-top: 45vh;
  }
}

@media (max-width: 1200px) {
  div.photo {
    padding-left: 40px;
  }

  .instagram-link {
    padding: 0;
  }
}
