.footer {
  height: 30vh;
  background-color: #1e1e1f;
  display: flex;
  flex-direction: column;
}

.footer h1 {
  margin-top: 0;
  color: white;
  padding-top: 15vh;
  margin-left: 10vw;
}

.svgs {
  display: flex;
  gap: 15px;
  margin-left: 10vw;
}

@media (max-width: 430px) {
  .footer h1 {
    padding-top: 8vh;
  }
}
