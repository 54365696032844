.picture {
  display: flex;
  justify-content: center;
  width: 100%;
}

.main-prof {
  min-height: 87vh;
  background: url("https://images.unsplash.com/photo-1635776063328-153b13e3c245?q=80&w=1932&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D");
  border-top-left-radius: 80px;
  border-top-right-radius: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.name {
  font-family: "Josefin Sans", sans-serif;
  font-size: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
}

ul.react-tabs__tab-list {
  display: flex;
  justify-content: center;
  gap: 100px;
  padding: 5px 0 2px 0;
  list-style: none;
  font-size: large;
  font-weight: 700;
  cursor: pointer;
  margin: 0;
  z-index: 1;
  font-family: "Unbounded", sans-serif;
  font-weight: 300;
}

ul.react-tabs__tab-list p {
  display: flex;
  justify-content: center;
  color: black;
  margin-bottom: 0;
  cursor: pointer;
}

.react-tabs__tab {
  padding: 10px;
}

.react-tabs__tab:hover {
  text-decoration: overline;
  transition: 1s;
}

.header-name {
  width: 100%;
  justify-content: center;
  padding-left: 0;
  text-align: center;
  margin-bottom: 0;
  font-family: "Unbounded", sans-serif;
  font-weight: 400;
}

header > h3 {
  width: 100%;
  justify-content: center;
  margin-top: 0;
  font-family: "Unbounded", sans-serif;
  font-weight: 300;
}

.react-tabs__tab--selected {
  text-decoration: overline;
  color: black;
}

.resume li {
  display: flex;
  align-items: center;
  gap: 5px;
}

header ul {
  list-style: none;
  display: flex;
  gap: 20px;
  justify-content: center;
}

h3 {
  text-align: center;
  text-decoration: none;
  padding-top: 20px;
}

section.about p {
  text-align: center;
}

.skills-in {
  display: flex;
  flex-direction: column;
  list-style: none;
  justify-content: space-between;
  padding-right: 30px;
  padding-left: 30px;
  flex-wrap: wrap;
  text-align: center;
}

section.skills {
  flex-direction: row;
  display: flex;
  justify-content: center;
}

.exp h2 {
  padding-left: 30px;
}

ul.experience {
  list-style: none;
}

.lets-work-together {
  font-size: 50px;
  margin-top: 10vh;
}

.resume a {
  text-decoration: none;
  color: black;
}

#dark .header-name{
  color: black;
}

#dark .lets-work-together{
  color: black;
}

#dark .resume header h3{
  color: black;
}

#dark .resume header ul li svg{
  color: black;
}

@media (max-width: 430px) {
  header ul {
    flex-direction: column;
  }

  .main-prof {
    min-height: 95vh;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    margin-top: -3vh;
  }

  .lets-work-together{
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 3vw;
  }

  ul.rw-list {
    margin-left: -20px;
  }
  ul.rw-list2 {
    margin-left: -20px;
  }

  footer {
    display: none;
  }
  ul.react-tabs__tab-list {
    display: flex;
    gap: 10px;
  }

  li.lines {
    display: none;
  }

  img.logos {
    width: 50px;
  }
}
