nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: "Montserrat", sans-serif;
  margin-left: 5vw;
  margin-right: 5vw;
  border-bottom: 1px solid #d6d6da;
}

.links {
  display: flex;
  flex-direction: row;
  gap: 1vw;
}

.logo {
  width: 100px;
}

a.nav-a {
  color: rgb(0, 0, 0);
  text-decoration: none;
  transform: rotate(0.0001deg);
  font-family: "Unbounded", sans-serif;
  padding: 14px;
  border-radius: 25px;
}

a.nav-a:hover {
  background-color: rgba(232, 231, 231, 0.534);
  transition: 0.2s;
}

@keyframes floating {
  0% {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(0, 5px);
  }
  100% {
    transform: translate(0, -0px);
  }
}

.nav-a:hover {
  animation-name: floating;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  transition: 1s ease-in-out;
}

@media (max-width: 430px) {
  img.logo {
    width: 50px;
    margin-top: 1vh;
  }

  nav {
    padding-right: 20px;
    font-size: smaller;
    justify-content: right;
  }

  .links{
    gap: 0;
  }

  a:hover {
    font-size: 14px;
    transition: none;
  }
}
