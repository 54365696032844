.logos {
  width: 100px;
  background-image: url(../Animations/Gradient.json);
}

.icons-container {
  width: 70%;
  display: flex;
  flex-wrap: wrap;
  gap: 100px;
  justify-content: center;
  margin-bottom: 40px;
}

.skills-container {
  margin-top: 40px;
  width: 100vw;
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
}
